<template>
  <section title-image-accordion :data-align="blok.align || 'left'" v-editable="blok" :data-style="blok.style || 'default'">
    <div class="container">
      <div class="section-row">
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
        <div v-if="blok.buttons?.length > 0" class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
      <div class="section-grid" v-if="blok.features?.length > 0">
        <div class="section-image">
          <img v-if="activeImage" :src="optimizeImage(activeImage)" loading="lazy" alt="Accordion Image" />
        </div>
        <div class="section-content">
          <div class="section-feature" v-for="(feature, f) in blok.features" :key="f" :class="{ active: active === f, 'active-prev': active - 1 === f }" @click="active = f">
            <div class="section-feature-title" v-if="feature.title"><h3 v-html="renderRichText(feature.title)"></h3></div>
            <div class="section-feature-description" v-if="feature.description">
              <div v-html="renderRichText(feature.description)"></div>
              <div class="section-feature-image" v-if="feature?.image_mobile?.filename">
                <img :src="optimizeImage(feature?.image_mobile?.filename)" alt="Feature Image" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/title-image-accordion.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });

const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));

const active = ref(0);
const align = computed(() => {
  return props.blok.align || "left";
});

const activeImage = computed(() => {
  if (props.blok.features[active.value]?.image?.filename) {
    return props.blok.features[active.value]?.image?.filename;
  }

  return props.blok.image?.filename;
});
</script>
